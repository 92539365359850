<template>
    <Main :show-title="!isLoading && !!activities.length">
        <template #header v-if="!isLoading && !!activities.length && subscription.isActive">
            <header class="card">
                <p class="columns">
                <span>
                    <small>Projekt startdato</small><br>
                    <span class="larger">{{ formatDateToFnsDate(projectFrom) }}</span>
                </span>
                    <span class="align-left">
                    <small>Projekt Slutdato</small><br>
                    <span class="larger">{{ formatDateToFnsDate(projectTo) }}</span>
                </span>
                </p>
                <ActionButton :small="true" :secondary="true" icon="camera"
                              label="Gem billede af visning" @click="takeSnapshot"/>
            </header>
        </template>

        <GanttChart v-if="!isLoading && !!activities.length" :from="projectFrom" :to="projectTo" :activities="activities" ref="timeline"/>
        <template v-else-if="!activities.length">
            <Empty v-if="!subscription.isActive" illustration="timeline">
                <template #title>Intet abonnement</template>
                <template #description>Tilknyt
                    <span @click="$router.push('/settings')" class="link">abonnement</span>
                    til konto
                </template>
            </Empty>
            <Empty v-else illustration="timeline">
                <template #title>Ingen tidsplaner<br>tilføjet endnu</template>
                <template #description>Tilføj din
                    <router-link to="/timeline/create">første</router-link>
                    tidsplan
                </template>
            </Empty>
        </template>

        <template v-if="false">
            <template v-if="isLoading">
                <CardList :columns="2">
                    <Placeholder v-for="placeholder in placeholders[0]" :key="placeholder" element="li"
                                 :classes="['card column-1']" :height="237"/>
                    <Placeholder v-for="placeholder in placeholders[1]" :key="placeholder" element="li"
                                 :classes="['card column-2']" :height="237"/>
                </CardList>
            </template>
            <Empty v-else-if="!activities.length" illustration="timeline">
                <template #title>Ingen tidsplaner<br>tilføjet endnu</template>
                <template #description>Tilføj din
                    <router-link to="/timeline/create">første</router-link>
                    tidsplan
                </template>
            </Empty>

            <template v-else-if="activitiesToday.length">
                <h2>I dag <span>{{ formatDateToFnsDate(new Date()) }}</span></h2>

                <CardList :columns="2">
                    <TimelineCard v-for="activity in activitiesToday" :key="activity.id"
                                  v-bind="activity"/>
                </CardList>
            </template>
        </template>
    </Main>

    <ActionButton icon="plus" :float="true" @click="$router.push('/timeline/create')" :disabled="!subscription.isActive" :tooltip="buttonTooltip"/>

    <ModelRouterView/>
</template>

<script>
import Main from '@/components/Main.vue';
import Empty from '@/components/Empty.vue';
import useUnsubscribe from '@/utils/useUnsubscribe';
import { projectsCollection, timelineCollection } from '@/utils/collections';
import ModelRouterView from '@/components/ModelRouterView.vue';
import ActionButton from '@/components/buttons/ActionButton.vue';
import CardList from '@/components/CardList.vue';
import Placeholder from '@/components/Placeholder.vue';
import TimelineCard from '@/components/cards/TimelineCard.vue';
import useState from '@/store';
import { onMounted, ref, watch } from 'vue';
import methods from '@/utils/methods';
import GanttChart from '@/components/GanttChart.vue';
import { add, isWithinInterval } from 'date-fns';
import html2canvas from 'html2canvas';

export default {
    name: 'Timeline',
    components: {
        Main,
        Empty,
        ModelRouterView,
        ActionButton,
        CardList,
        Placeholder,
        TimelineCard,
        GanttChart,
    },
    mixins: [methods],
    setup() {
        const { collections, project, subscription } = useState();
        const placeholders = collections.value?.activities || [0, 0];
        const {
            docs,
            isLoading,
        } = useUnsubscribe(timelineCollection().orderBy('from'), placeholders);
        const projectFrom = ref(new Date());
        const projectTo = ref(new Date());
        const activitiesToday = ref([]);
        const upcomingActivities = ref([]);

        watch(docs, (activities) => {
            activitiesToday.value = activities.filter((activity) => {
                const from = activity.from.toDate();
                const to = add(from, { days: activity.days - 1 });
                return isWithinInterval(new Date(), { start: from, end: to });
            });

            // upcomingActivities.value = activities.filter((activity) => {
            //     // const from = activity.from.toDate();
            //     // const to = add(from, { days: activity.days - 1 });
            //     // console.log(activity.from.toDate());
            //     // return isWithinInterval(activity.from.toDate(), { start: new Date(), end: projectTo.value });
            //     return true;
            // });
        });

        onMounted(async () => {
            const doc = await projectsCollection().doc(project.value.id).get();
            projectFrom.value = doc.data().from.toDate();
            projectTo.value = doc.data().to.toDate();
        });

        return {
            activities: docs,
            isLoading,
            placeholders,
            projectFrom,
            projectTo,
            activitiesToday,
            upcomingActivities,
            subscription,
        };
    },
    computed: {
        buttonTooltip() {
            return this.subscription.isActive ? 'Opret opgave' : 'Tilknyt et abonnement til din konto, for at oprette opgaver';
        },
    },
    methods: {
        async takeSnapshot() {
            const canvas = this.$refs.timeline.$el.querySelector('div.timeline');
            const print = await html2canvas(canvas, {
                allowTaint: true,
                useCORS: true,
                height: canvas.clientHeight + 100,
                y: -50,
            });

            const a = document.createElement('a');
            a.download = 'tidsplan.jpg';
            a.href = print.toDataURL('image/png').replace('image/jpeg', 'image/octet-stream');
            a.click();
        },
    },
};
</script>

<style lang="less" scoped>
main {
    display: grid;
    grid-template-rows: auto 1fr;
}

header {
    display: flex;
    justify-content: space-between;

    p {
        width: clamp(300px, 50%, 500px);
    }

    button {
        padding: 0 2.6rem;
    }
}

h2 {
    font-size: 4.2rem;
    line-height: 1.3;
    font-weight: var(--semibold-weight);
    margin-bottom: 2rem;

    span {
        color: rgb(var(--grey-color));
        margin-left: 0.3em;
    }
}
</style>
