<template>
    <span class="status-label" draggable="true" @click="onEvent()" :data-tooltip="`${subcontract} - ${description} fra ${tooltip}`" ref="span"
          :style="`grid-area: ${row} / ${column} / ${row} / span ${days}; background-color: ${getSubcontractColor(subcontract)}`">
        <i ref="text" :style="`left: ${offsetLeft}px`"><span>{{ subcontract }} - {{ description }}</span></i>
    </span>
</template>

<script>
import { differenceInCalendarDays } from 'date-fns';
import methods from '@/utils/methods';
import { onMounted, ref } from 'vue';

export default {
    name: 'Activity',
    props: {
        row: {
            type: Number,
            required: true,
        },
        id: {
            type: String,
            default: null,
        },
        created: {
            type: Object,
            default: null,
        },
        subcontract: {
            type: String,
            default: null,
            // required: true,
        },
        description: {
            type: String,
            default: null,
        },
        from: {
            type: Object,
            default: null,
            // required: true,
        },
        to: {
            type: Object,
            default: null,
        },
        days: {
            type: String,
            default: () => '1',
        },
        projectStart: {
            type: Date,
            default: null,
        },
        scrolled: {
            type: Number,
            default: 0,
        },
    },
    setup() {
        const isMounted = ref(false);

        onMounted(() => {
            isMounted.value = true;
        });

        return { isMounted };
    },
    mixins: [methods],
    computed: {
        tooltip() {
            return `${this.formatDateToFnsDate(this.from.toDate())} til ${this.formatDateToFnsDate(this.to?.toDate())}`;
        },
        column() {
            return differenceInCalendarDays(this.from.toDate(), this.projectStart) + 1;
        },
        offsetLeft() {
            if (!this.isMounted) return 0;

            let left = this.scrolled - this.$refs.span.offsetLeft;
            const right = this.$refs.span.clientWidth - this.$refs.text.clientWidth;
            const stop = left + this.$refs.text.clientWidth >= this.$refs.span.clientWidth;

            left = left < 0 ? 0 : left;
            left = stop ? right : left;

            return left;
        },
    },
    methods: {
        onEvent() {
            this.$router.push({ name: 'edit-timeline', params: { id: this.id } });
        },
        getSubcontractColor(subcontract) {
            const colors = new Map([
                ['murer', '#14B18C'],
                ['tømrer', '#2F137E'],
                ['maler', '#BE2069'],
                ['vvs', '#6a20be'],
                ['el', '#207fbe'],
                ['ventilation', '#be3220'],
                ['tagdækker', '#be6420'],
                ['anlægsgartner', '#20beb1'],
                ['kloak', '#25be20'],
                ['beton', '#bebb20'],
                ['inventar', '#c373dc'],
                ['arkitekt', '#aaaaaa'],
                ['ingeniør', '#cccccc'],
                ['myndighed', '#ffa678'],
                ['bygherre', '#c9a2ca'],
            ]);

            return colors.get(subcontract.toLowerCase());
        },
    },
};
</script>
<style lang="less" scoped>
i {
    display: flex;
    align-items: center;
    position: absolute;
    height: 100%;
    inset: 0 auto 0 0;
    padding-inline: 12px;
    overflow: hidden;
    max-width: 100%;
    box-sizing: border-box;

    span {
        overflow: hidden;
        font-style: normal;
        text-overflow: ellipsis;
    }
}
</style>
