<template>
    <div class="gantt-chart">

        <!-- <Tabs label="Visning" :tabs="tabs" v-model="view" /> -->

        <div :class="`timeline ${view}`" ref="timeline" @scroll.passive="scroll">
            <ul v-if="view === 'days'" class="lines"
                :style="`grid-template-columns: repeat(${days.length}, minmax(60px, 1fr)); min-height: ${height}px;`">
                <li v-for="(day, index) in days" :key="index"
                    :class="{ 'today' : dateIsToday(day), 'weekend' : dateIsWeekend(day) }"
                    :data-week="startOfWeek(day)">
                    <span>{{ formatDateToFnsDate(day, 'EEE') }}<br>
                    {{ formatDateToFnsDate(day, 'd. LLL') }}</span>
                </li>
            </ul>
            <ul v-else-if="view === 'weeks'" class="lines"
                :style="`grid-template-columns: repeat(${weeks.length}, minmax(140px, 1fr))`">
                <li v-for="(week, index) in weeks" :key="index">
                    Uge {{ formatDateToFnsDate(week, 'w') }}
                </li>
            </ul>
            <div class="activities" :style="`grid-template-columns: repeat(${days.length}, minmax(60px, 1fr))`">
                <Activity v-for="(activity, index) in activities" :key="index" v-bind="activity" :row="index + 1"
                          :project-start="from" :scrolled="scrolled" />
            </div>
        </div>

    </div>
</template>

<script>
import { eachDayOfInterval, eachWeekOfInterval, isToday, isWeekend, isMonday, isAfter } from 'date-fns';
import methods from '@/utils/methods';
import useState from '@/store';
import Activity from '@/components/Activity.vue';
// import Tabs from '@/components/Tabs.vue';

export default {
    name: 'GanttChart',
    components: {
        Activity,
        // Tabs,
    },
    props: {
        from: {
            type: Date,
            required: true,
        },
        to: {
            type: Date,
            required: true,
        },
        activities: {
            type: Array,
            default: () => [],
        },
    },
    mixins: [methods],
    setup() {
        const { setModalData } = useState();
        return { setModalData };
    },
    data() {
        return {
            view: 'days',
            tabs: [
                { value: 'days', label: 'Dage' },
                { value: 'weeks', label: 'Uger' },
                { value: 'months', label: 'Måneder' },
            ],
            scrolled: 0,
            height: 0,
        };
    },
    mounted() {
        setTimeout(() => {
            const { timeline } = this.$refs;
            const today = this.$el.querySelector('ul.lines li.today')?.offsetLeft;
            const scroll = isAfter(new Date(), this.to) ? timeline.scrollWidth - timeline.offsetWidth : today - timeline.offsetWidth * 0.2;
            this.scrollToElement(timeline, scroll);
            this.height = timeline.querySelector('div.activities').offsetHeight;
        }, 100);
    },
    computed: {
        days() {
            return eachDayOfInterval({ start: new Date(this.from), end: new Date(this.to) });
        },
        weeks() {
            return eachWeekOfInterval({ start: new Date(this.from), end: new Date(this.to) }, { weekStartsOn: 2 });
        },
    },
    methods: {
        dateIsToday(date) {
            return isToday(date);
        },
        dateIsWeekend(date) {
            return isWeekend(date);
        },
        startOfWeek(date) {
            return isMonday(date) ? this.formatDateToFnsDate(date, 'w') : null;
        },
        scrollToElement(element, offset) {
            let start;
            const duration = 2000;
            const easing = (t) => (t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1);

            if (!offset) return;

            window.requestAnimationFrame(function step(timestamp) {
                if (!start) start = timestamp;

                const time = timestamp - start;
                const percent = easing(Math.min(time / duration, 1));
                element.scrollTo(offset * percent, 0);
                if (time < duration) window.requestAnimationFrame(step);
            });
        },
        scroll() {
            const { timeline } = this.$refs;
            this.scrolled = timeline.scrollLeft;
        },
    },
};
</script>

<style scoped lang="less">
.gantt-chart {
    overflow: hidden;
    //margin: 50px 0;

    header {
        justify-content: flex-end;
        margin-bottom: 40px;
    }

    .timeline {
        padding-top: 20px;
        overflow: auto;
        position: relative;
        height: calc(100% - 20px);

        --scroll-bottom-push: calc(20px - var(--scroll-bar-width));

        &::-webkit-scrollbar {
            background: rgba(var(--dark-grey-color), 10%);
            border: solid transparent;
            background-clip: padding-box;
            height: 20px;
            border-width: var(--scroll-bottom-push) 0 0 calc(var(--scroll-bottom-push) * 0.8);
        }

        &::-webkit-scrollbar-thumb {
            background: rgba(var(--dark-grey-color), 60%);
            border: solid transparent;
            background-clip: padding-box;
            border-width: var(--scroll-bottom-push) 0 0 calc(var(--scroll-bottom-push) * 0.8);
        }
    }

    .activities {
        display: grid;
        grid-gap: 5px 0;
        padding: 60px 0 30px;

        span.status-label {
            cursor: pointer;
            overflow: visible;
            color: rgb(var(--white-color));
            background: rgb(var(--primary-color));
            min-width: 60px;
            box-sizing: border-box;
            justify-content: flex-start;
        }
    }

    ul.lines {
        display: grid;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;
        list-style: none;
        padding: 0;
        margin: 0;

        li {
            color: rgb(var(--semi-light-grey-color));
            white-space: nowrap;
            font-weight: 700;
            font-size: 1.2rem;
            text-transform: uppercase;
            text-align: center;
            padding-top: 15px;

            &::first-line {
                font-size: 1.3em;
            }

            &.weekend {
                background-image: linear-gradient(rgba(var(--light-grey-color), 0%),
                rgb(var(--light-grey-color)) 30%,
                rgb(var(--light-grey-color)) 70%,
                rgba(var(--light-grey-color), 0%) 100%);
            }

            &.today {
                color: rgb(var(--primary-color));
                background-image: linear-gradient(rgba(var(--primary-color), 0%),
                rgba(var(--primary-color), 20%) 30%,
                rgba(var(--primary-color), 20%) 70%,
                rgba(var(--primary-color), 0%) 100%);
            }

            &:not(:first-child) {
                border-left: 1px solid rgba(var(--black-color), 7%);
            }

            &[data-week]::before {
                content: "Uge " attr(data-week);
                display: block;
                margin-top: -1.6rem;
                line-height: 1;
                margin-bottom: 0.3rem;
            }
        }
    }

}
</style>
